


import React from "react"
import { graphql } from "gatsby"

import Bio from "../../components/bio"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Community = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <Bio />
      <h3>The Community Has Said:</h3>
      <blockquote>"Rabbi Fine is a unique person who is able to combine his spirituality, training in family systems, and experience with life on the streets, into programs that have a profound impact on teens, parents and professionals working with teens.
"During his work in Baltimore, he touched teens by his stories, values, and straight talk. In turn, those teens were inspired to encourage their parents to attend a parent symposium with Rabbi Fine. These programs were conducted in both public schools and Jewish Day Schools. Our parent symposium was truly a historical event in the community because it attracted Reform, Conservative, Orthodox, and unaffiliated Jews, as well as Russian Jewish emigrants.<br/>- Joan Grayson Cohen, Esq. LCSW-C<br/>Manager, Child / Adolescent Outreach, Jewish Family Services<br/>Baltimore, MD</blockquote>

<blockquote>"Yehudah Fine may be the only rabbi who trained on the fringe of civilization. Today a family therapist and lecturer on drugs, depression, sexuality and spirituality, he spent ten years as the 'Times Square Rabbi,' nightly patrolling New York, reaching out to druggies, runaways, and prostitutes."<br/>- Alan M. Tighe<br/>
Executive Editor<br/>Hadassah Magazine</blockquote>


<blockquote>"The first clue that Yehudah Fine is not your every day American rabbi is the NY Yankees baseball hat. He introduces himself as Yehudah, insisting, 'Please don't call me rabbi. There are too many titles in this world.'
"For over 10 years his congregation was a gritty mixture of white, black, Jewish, Christian, wealthy, and inner city homeless teens in New York's Times Square. He is articulate, soft-spoken and scholarly, yet he loved prowling the dark, rat infested subway tunnels and garbage piles to reach teens no one else wanted."<br/>- Barbara Cooke<br/>
Chicago Tribune</blockquote>


<blockquote>"Rabbi Fine really connected beautifully with the teenagers he talked to. We were amazed to see how mesmerized the kids were coming from the gamut of the socio-economic spectrum. Some Jewish kids, some not, some from runaway shelters with their counselors. They just sat in complete attention. Yehudah has a great talent of connecting with those kids, by talking right to them."<br/>Steve Schwartz<br/>
Jewish Family Services<br/>
Rochester, NY</blockquote>



<blockquote>"Yehudah just tuned in to our high-risk kids and could pinpoint their problems right away. Suddenly he blended with them. It was so powerful to watch these kids open up. At the end of two days, they were amazed by how much they had bonded to him.. They didn't want him to go. They each wanted a little piece of him."Kate Morgan<br/>
Grace Webb High School<br/>
Hartford Hospital<br/>
Hartford, CT</blockquote>

<blockquote>"Our students were spellbound by the frankness of the accounts of 'lost' kids their own age, and of the candor of the tale-teller, Yehudah Fine. 'Yehudah tells the story in a way that holds my attention completely. He doesn't drag it out and go 'round and 'round with it like lots of other people do,' commented one young student. 'This is real life; you can tell it by Yehudah's direct, no nonsense reporting of things,' said another. 'The way he talks and writes makes me feel like I am right there on the scene, witnessing things firsthand,' a young woman added."<br/>- Dr. Pat Montgomery<br/>
National Coalition of Alternative Community Schools & Clonlara School<br/>
Ann Arbor, MI</blockquote>


<blockquote>"Significantly as a writer and as a speaker, Rabbi Fine shares his message pointedly and with clarity allowing each of us the privilege to define the way in which we help others. Yehudah is selfless, indefatigable, and as helped more people in need then any other single person I know."<br/> - Dr. Sandy Andron<br/>Central Agency for Jewish Education<br/>Miami, FL</blockquote>

<blockquote>"Rabbi Fine's honesty resonated with the parents in the audience, but what I really liked about Yehudah was his realistic approach in acknowledging the difficulty that parents go through with their teens. All while drawing on his own experiences with his children."<br/>- Margo Lazar<br/>
Jewish Federation of Metropolitan Detroit<br/>
Detroit, MI	</blockquote>


<blockquote>"Over 700 teens, parents and professionals came to hear Yehudah address the issues facing teens in today's world. His was a message of hope, endurance and love. His presentations ignited a community commitment to teens."<br/>- Debra H. Levenstein<br/>
Program Director<br/>
Family Life Education<br/>
Jewish Family and Children's Service of Minneapolis</blockquote>


<blockquote>"We've made Yehudah's work a part of our family education program. Our parents and teens were so moved by his compassion. It's very clear that he has been listening carefully to the thousands of young people with whom he has worked."<br/>- Howard A. Addison<br/>
The Enneagram and Kabbalah: Reading Your Soul and
Show Me Your Way: The Complete Guide to Exploring Interfaith Spiritual Direction</blockquote>
 

<blockquote>"Yehudah is a healer. He has an energy that we don't see often in our lifetime. His forgiveness conferences replace a negative idea with a positive attitude. People leave with hope and optimism for a better life."<br/>- Elaine Johnson
Community Forum Leader<br/>
America Online<br/>
Addiction & Recovery Forum</blockquote>


<blockquote>"Rabbi Fine's insights and sound advice on reaching our youth are honest and refreshing. He is genuine, provocative and thoughtful. Best of all, he's touchable. In my own youth, I would have referred to him as 'one cool rabbi'."<br/>- Bob Silver<br/>Jewish Family Services<br/>Memphis,TN</blockquote>

<blockquote>"All of us learned a great deal from Yehudah's stimulating programs, and we now have a deeper insight into teenagers and parenthood. We are very pleased to be beneficiaries of Yehudah's wisdom."<br/>- Sharon Goldberg<br/>
Program Coordinator<br/>Adult Services<br/>JCC of Greater Pittsburgh</blockquote>


<blockquote>
"Rabbi Fine provided a forum for teens to talk about the tough issues of our time. Only in listening to these teens does Yehudah discover the answers to every parent's dilemma. His compassion may only be out done by his enthusiasm and genuine concern for all who attend."<br/>- Rebecca Dallet<br/>
Congregation Shalom<br/>
Milwaukee, WI</blockquote>



<blockquote>"For years Yehudah Fine's one man mission was to throw runaways a lifeline to help them find a way off the streets and back into the daylight world of the living. Today Yehudah crisscrosses America staging workshops for both teenagers and their parents in public schools, national alternative schools and homeschool conventions while he is putting on the final touches on his latest book of advice to parents of teenage children."<br/>- Chris Mercogliano<br/>Editor<br/>Journal for Living</blockquote>


<blockquote>"Rabbi Fine's work is an invitation to us all. The rabbi is modeling a path for healing the wounds of denial and unexpressed love we carry. The Rabbi teaches us that when we truly become engaged in life, our appreciation for being alive grows. He shows us a road that passes through others' lives and leads us back to ourselves. But we arrive home enriched, in touch, and with a larger heart as a result of the journey."<br/>- Earl Hipp<br/> Help for the Hard Times: Getting Through Loss and other books for adolescents</blockquote>


<blockquote>"Religion is for people who wish to avoid going to hell. Spirituality is for people who have been there. Yehudah Fine is a spiritual rabbi who has dedicated his life to helping young people emerge from hell, and to prevent their reentry into it."
<br/>- Abraham J. Twerski<br/>Founder and Medical Director<br/>Gateway Rehabilitation Center</blockquote>


<blockquote>"Yehudah's meaningful stories about teens thoroughly fascinated our audience. His heart warming presentation was well received as could be seen by the response of those present."<br/>
- Helaine Gurgold<br/>
- Barbara Hoppenfeld<br/>
- Nancy Kane<br/>
JCC of Davie, Florida</blockquote>

<blockquote>"Rabbi Fine shows up looking exactly like the photograph on his book cover: baseball cap; neatly trimmed beard; dark rimmed glasses; casual clothes; atypically rabbinic, if he's to be compared to any sort of rabbinic stereotype. After all, how many rabbis can show up at techno clubs and receive preferential treatment from tank-sized bouncers?"<br/>- Susan Josephs<br/>
Saving Souls In Times Square<br/>
The Jewish Week</blockquote>

<blockquote>"Yehudah has a spiritual glow about him, but you have to understand that he's a dynamic trained family therapist as well as a rabbi. Truthfully, he stands head and shoulders above everyone else. Teens can tell when someone is real - you can't fool them. Every kid who meets him says he's someone special."<br/>- Dr. E. Nulman<br/>
Wurzweiller School of Social Work<br/>
Yeshiva University, New York<br/>
</blockquote>


<blockquote>"We want to thank Yehudah for the spectacular job he did. The feedback from the staff training and lecture has been extremely positive. We hope to schedule another event with him in the future."<br/>- Eileen Berman<br/>Director of Adult Services
JCC of Greater Baltimore</blockquote>

<blockquote>
"It was a pleasure to have Yehudah Fine speak to our staff and community members. We were joined by many community social workers and rabbis. Much understanding was gained from his direct approach, information, and his straightforward way of hitting sensitive issues."<br/>- Meira Itzkowitz, LCSW<br/>
Jewish Family & Children's Service of Southern New Jersey<br/></blockquote>
    </Layout>
  )
}

export default Community

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
